<template>
  <div class="closed-invoice-profile">
    <custom-validate-input :label="$t('closed.labels.description')"
                           align="right"
                           rtl
                           :is-empty="true"
                           :disabled="true"
                           :value="closeDescription"/>
    <div class="confirm-table">
      <draggable-dynamic-table ref="ClosedInvoiceProfile"
                               v-if="data.length"
                               :in-modal="true"
                               :active-grid="true"
                               :fix-screen="true"
                               :options="options"
                               :columns="columnsLabel"
                               v-model="data"/>
    </div>
  </div>
</template>

<script>
  import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
  import {getClosedInvoice} from "../../../../../http/requests/club/closedInvoices";

  export default {
    name: "closedInvoiceProfile",
    components: {CustomValidateInput},
    metaInfo () {
      return {
        title: this.$t('closed.profile.title')
      }
    },
    data () {
      return {
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'timeReserve',
            i18n: 'closed.table.header.timeReserve',
            width: '150px',
            minWidth: 150,
          },
          {
            field: 'date',
            i18n: 'closed.table.header.date',
            align: 'center',
            width: 'calc(25%)',
            minWidth: 150,
            /*sortable: true,*/
          },
          {
            field: 'name',
            i18n: 'closed.table.header.courtName',
            align: 'center',
            width: 'calc(40%)',
            minWidth: 150,
            footer: {}
          },
          {
            field: 'rowNumber',
            i18n: 'closed.table.header.row',
            align: 'center',
            width: '70px',
            minWidth: 70,
            autoIncrement: true,
            footer: {
              type: 'auto-counter'
            }
          }
        ],
        firstVisit: true,
        firstVisitTimer: 0,
        data: [],
        closeDescription: {
          value: '',
          isValid: false
        },
        total_count: 0,
        page: 0,
        actions: [
          {
            toolbar: [
              {
                id: {name: 'editClosedInvoice'},
                type: 'link',
                icon: 'EDIT',
                iconPack: 'useral',
                color: 'warning',
                permission: 'closed_invoice.update'
              }
            ],
            leftToolbar: [
              {
                id: {name: 'closedInvoiceActivitiesLog', params: {id: this.$route.params.id}},
                type: 'link',
                icon: 'LAST_ACTIVITIES',
                iconPack: 'useral',
                permission: 'activity_logs.show'
              },
              {
                id: 'routeBackBTN',
                route: {name: 'closedInvoices'},
                icon: 'CHEVRON_LEFT',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
      })

      this.getClosedInvoice()
    },
    methods: {
      getClosedInvoice () {
        getClosedInvoice(this.$route.params.id).then(response => {
          const closedInvoice = response.data.data
          this.closeDescription = {
            value: closedInvoice.note,
            isValid: true
          }

          if (closedInvoice.lines.length > 0) {
            closedInvoice.lines.forEach(item => {
              this.data.push({
                name: item.court.name,
                date: item.date.split(' ')[0],
                timeReserve: `${item.time.start_time.substr(0, 5)} ${this.$t('closed.labels.until')} ${item.time.end_time.substr(0, 5)} `,
              })
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .closed-invoice-profile {
    height: calc(100vh - 165px);
    background-color: #ffffff;
    padding: 0.5rem;
    border: 1px solid #cdcdcd;
    border-top: 0;
    border-radius: 0 0 0.5rem 0.5rem;

    .confirm-table {
      height: calc(100% - 65px);
    }
  }
</style>
