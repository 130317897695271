import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getClosedInvoices: createCancelTokenHandler('getClosedInvoices'),
  getClosedInvoiceTrash: createCancelTokenHandler('getClosedInvoiceTrash'),
  getClosedInvoiceActivitiesLog: createCancelTokenHandler('getClosedInvoiceActivitiesLog')
}


export function getClosedInvoices (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/club/closed-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getClosedInvoices'].handleRequestCancellation().token
  })
}

export function getClosedInvoice (id) {
  return axios({
    url: `v1/admin/club/closed-invoices/${id}`,
    method: 'get'
  })
}

export function getClosedInvoiceActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/club/closed-invoices/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getClosedInvoiceActivitiesLog'].handleRequestCancellation().token
  })
}

export function editClosedInvoice (id, payment) {
  return axios.patch(`v1/admin/club/closed-invoices/${id}`, payment)
}

export function insertClosedInvoice (payment) {
  return axios({
    url: 'v1/admin/club/closed-invoices',
    data: payment,
    method: 'post'
  })
}

export function deleteClosedInvoice (id, force = false) {
  return axios({
    url: `v1/admin/club/closed-invoices/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreClosedInvoice (id) {
  return axios({
    url: `v1/admin/club/closed-invoices/${id}/restore`,
    method: 'put'
  })
}

export function getClosedInvoiceTrash (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/club/closed-invoices?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getClosedInvoiceTrash'].handleRequestCancellation().token
  })
}
